export default {
  errorOccurred: "Une erreur s'est produite",
  myAccount: 'Mon compte',
  myStatistics: 'Mes statistiques',
  profilePicture: 'Photo de profil',
  notDefined: 'Non défini',
  unknown: 'Inconnu',
  saved: 'Enregistré',
  youMustLogInAgain:
    'Vous devez vous reconnecter pour pouvoir modifier votre email',
  youMustLogInAgainToDeleteYourAccount:
    'Vous devez vous reconnecter pour pouvoir supprimer votre compte',
  createAnAccount: 'Créer un compte',
  incorrectCredentials: 'Identifiants incorrects',
  alreadyUsedEmail: 'Cette addresse email est déjà utilisée',
  invalidEmail: 'Email invalide',
  tooShortPassword: 'Mot de passe trop court',
  firstNameIsRequired: 'Prénom requis',
  lastNameIsRequired: 'Nom requis',
  emailIsRequired: 'Email requis',
  passwordIsRequired: 'Mot de passe requis',
  password: 'Mot de passe',
  cancel: 'ANNULER',
  submit: 'VALIDER',
  terminate: 'TERMINER',
  confirm: 'Confirmer',
  emailModification: "Changement d'email",
  accountDeletion: 'Suppression de compte',
  firstName: 'Prénom',
  lastName: 'Nom',
  email: 'Email',
  emailSent: 'Email envoyé',
  login: 'SE CONNECTER',
  forgottenPassword: 'Mot de passe oublié',
  loading: 'Loading...',
  close: 'FERMER',
  back: 'Retour',
  gender: 'Genre',
  selectPhoto: 'Sélectionner une photo',
  chooseFromGallery: 'Choisir depuis la galerie',
  takePicture: 'Prendre une photo',
  next: 'SUIVANT',
  continue: 'CONTINUER',
  minutes: 'minutes',
  seconds: 'secondes',
  hour: 'heure',
  hourAndHalf: 'heure et demie',
  hours: 'heures',
  anErrorOccurred: "Une erreur s'est produite",
  sorryAnErrorOccurred: "Désolé, une erreur s'est produite",
  date: 'Date',
  time: 'fois',
  times: 'fois',
  hello: 'Bonjour ',
  phoneNumber: 'Numéro de téléphone ',
  address: 'Adresse ',
  deleted: 'Supprimé',

  waitingMatchingRepairers: 'En attente de réparateurs',
  bikeNotReceivedYet: 'Vélo à emmener au réparateur',
  waitingForEstimate: 'En attente de devis',
  waitingForEstimateValidation: 'Devis à valider',
  estimateRefused: 'Devis refusé',
  waitingForReparation: 'Travail en cours',
  waitingForRemoval: 'À récupérer',
  tookBackByOwner: 'Vélo réceptionné',
  bikeReceived: 'Vélo déposé',
  bikeToBeReceived: 'Vélo à apporter',
  runningControl: 'Vélo en cours de contrôle',
  certifiedControl: 'Contrôle certifié',
  optionalOperations: 'Interventions optionnelles',
  repairedBike: 'Vélo réparé, prêt à être récupéré',
  codeToBeGiven: 'Code à donner : ',
  brakes: 'Freins',
  gears: 'Vitesses',
  chain: 'Chaine',
  frame: 'Cadre',
  addReparation: 'Ajouter une réparation',
  pendingControl: 'Contrôle en attente',
  optionalProcedures: 'Interventions optionnelles ',
  electricalAssistance: 'assistance\nélectrique',
  addPhotos: 'ajouter des photos',
  termsOfService: 'Conditions générales',
  confidentiality: 'Politique de confidentialité',
  unknownFeminine: 'inconnue',
};
