import React, {useState} from 'react';
import _ from 'lodash';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import colors from '../theme/colors';
import reparationStatutes from '../reparations/reparationStatutes';
import reparationTypesEnum from './reparationTypesEnum';
import {updateReparation} from '../reparations/reparationsActions';
import {sendBikeEntryNotification} from '../notifications/sendNotification';
import {controlBeforeSale} from '../reparations/problems';
import Reparation from '../reparations/Reparation';

const BikeEntries = ({entries}) => {
  const [filteredBikeEntries, setFilteredBikeEntries] = useState<Reparation[]>(
    entries.sort((a, b) => a.exitDate - b.exitDate),
  );
  const [entryToValidate, setEntryToValidate] = useState<any>();
  const [code, setCode] = useState<number | null>();
  const [toastMessage, setToastMessage] = useState<string>();

  const checkCode = async (enteredCode, realCode) => {
    try {
      const code = Number(enteredCode.target.value);

      setCode(code);
      if (code === realCode) {
        await markAsReceived(entryToValidate);
        setEntryToValidate(null);
        setToastMessage('Code correct, vélo marqué réceptionné');
      }
    } catch {}
  };

  const reparationTypeText = entry => {
    if (entry.reparationType === reparationTypesEnum.urgency) return 'Urgence';
    if (entry.reparationType === reparationTypesEnum.appointment)
      return 'Rendez-vous';
    if (entry.reparationType === reparationTypesEnum.immediate)
      return 'Réparation sous 48 heures';
  };

  const markAsReceived = async entry => {
    const newStatus =
      entry.problem === controlBeforeSale
        ? reparationStatutes.runningControl
        : reparationStatutes.waitingForEstimate;

    await Promise.all([
      updateReparation(entry.uid, {
        status: newStatus,
        bikeReceivedAt: new Date(),
      }),
      sendBikeEntryNotification(entry.user.uid),
    ]);
  };

  const filterEntries = e => {
    if (e.target && e.target.value !== '') {
      setFilteredBikeEntries(
        entries.filter(b => {
          if (b && b.user) {
            return (
              b.user.firstName
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) >= 0 ||
              b.user.lastName
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) >= 0
            );
          } else {
            return false;
          }
        }),
      );
    } else {
      setFilteredBikeEntries(entries);
    }
  };

  return (
    <MainContainer>
      <SearchBar>
        <SSearchIcon />
        <TextField
          label="Filtrer par nom de client"
          onChange={filterEntries}
          name="filter"
        />
      </SearchBar>

      {filteredBikeEntries.map((entry, i) => (
        <List dense>
          <ListItem style={i % 2 ? {backgroundColor: colors.greyA200} : {}}>
            <ListItemText
              primary={
                entry.title +
                ' -- ' +
                _.upperFirst(entry.user.firstName) +
                ' ' +
                _.upperFirst(entry.user.lastName)
              }
              secondary={
                // @ts-ignore
                moment((entry.start || entry.creationDate).toDate()).format(
                  'LLL',
                )
              }
            />

            <ReparationType>{reparationTypeText(entry)}</ReparationType>

            <Button
              variant="contained"
              color="primary"
              onClick={() => setEntryToValidate(entry)}>
              <strong>Marquer réceptionné</strong>
            </Button>
          </ListItem>
        </List>
      ))}

      {entryToValidate && (
        <Dialog
          open={true}
          onClose={() => setEntryToValidate(null)}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Réparation</DialogTitle>

          <DialogContent style={{alignItems: 'center'}}>
            <DialogContentText>
              Veuillez entrer le code à 4 chiffres que vous fournit le client :
            </DialogContentText>
            <InputContainer>
              <TextField
                defaultValue={''}
                onChange={code =>
                  checkCode(code, entryToValidate.bikeEntryCode)
                }
                autoFocus
                margin="dense"
                id="code"
                label="Code"
                type="number"
                size="small"
              />
            </InputContainer>
          </DialogContent>

          <DialogActions style={{justifyContent: 'space-between'}}>
            <div>
              <Button onClick={() => setEntryToValidate(null)} color="primary">
                Annuler
              </Button>
              <Button
                onClick={() => {
                  if (code === entryToValidate.bikeEntryCode) {
                    return markAsReceived(entryToValidate);
                  } else {
                    setToastMessage('Code erroné, veuillez réessayer');
                  }
                }}
                color="primary">
                Valider
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={() => setToastMessage('')}
        message={toastMessage}
        action={
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => setToastMessage('')}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastMessage('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </MainContainer>
  );
};

export default BikeEntries;

const MainContainer = styled.div`
  //max-width: 1200px;
  display: flex;
  flex-direction: column;
`;

const ReparationType = styled.span`
  color: ${colors.palette3};
  font-weight: bold;
  margin-right: 20px;
`;

const SearchBar = styled.form`
  align-items: end;
  justify-content: center;
  display: flex;
`;

const SSearchIcon = styled(SearchIcon)`
  margin-right: 12px;
  color: ${colors.palette3};
  font-size: 30px;
`;

const InputContainer = styled.div`
  justify-content: center;
  display: flex;
`;
