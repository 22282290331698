import React from 'react';
import {ThemeProvider} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import firebase from 'firebase';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {ToastProvider} from 'react-toast-notifications';

import './util/ReactotronConfig';
import theme from './theme/theme';
import './theme/styles.css';
import config from './firebase/firebaseConfig';
import AuthenticatedApp from './navigation/AuthenticatedApp';
import UnauthenticatedApp from './navigation/UnauthenticatedApp';
import useAuth from './auth/authContext';
import CssBaseline from '@material-ui/core/CssBaseline';

function App() {
  moment.locale('fr');

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.analytics();

    if (window.location.hostname === 'localhost') {
      firebase.auth().useEmulator('http://localhost:9099');
      firebase.firestore().settings({
        host: 'localhost:8080',
        ssl: false,
      });
    }
  }

  if (
    !(
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  ) {
    Notification.requestPermission();
  }

  const user = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider
        placement="bottom-right"
        autoDismiss
        autoDismissTimeout={3000}>
        <CssBaseline />

        <Helmet>
          <meta charSet="utf-8" />
          <title>Back-office réparateur</title>
          <meta name="description" content="Back-office réparateur" />
        </Helmet>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
          {/*<AuthenticatedApp />*/}
        </MuiPickersUtilsProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
