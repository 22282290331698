import React from 'react';
import {Helmet} from 'react-helmet';
import {Button, Link} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import routes from './routes';
import I18n from '../translations/i18n';

export default () => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Biket</title>
        <meta name="description" content="Biket" />
      </Helmet>

      <MainContainer>
        <RepairerButtonContainer>
          <Button
            variant="contained"
            color="secondary"
            style={{color: 'white'}}
            onClick={() => history.push(routes.login)}>
            <strong>Je suis réparateur</strong>
          </Button>
        </RepairerButtonContainer>

        <img
          alt="Biket logo"
          src={require('../assets/biket.png')}
          style={{width: '60%', maxWidth: (305 / 5) * 6}}
        />
        <BaselineContainer>
          <Baseline>
            Première plateforme de vente de vélos d'occasion contrôlés par des
            réparateurs agréés.
          </Baseline>
        </BaselineContainer>
        {/*<img alt="Biket animated logo" src={require('../assets/logo.gif')} />*/}

        <StoresButton>
          <Link href="https://apps.apple.com/us/app/biket/id1527491487">
            <img
              alt="App Store button"
              src={require('../assets/appStore.jpeg')}
              style={{width: (305 / 5) * 3, marginRight: 10}}
            />
          </Link>

          <Link href="https://play.google.com/store/apps/details?id=com.biket">
            <img
              alt="Play Store button"
              src={require('../assets/playStore.png')}
              style={{width: (305 / 5) * 3, marginLeft: 10}}
            />
          </Link>
        </StoresButton>

        <Footer>
          <SLink href={routes.confidentiality} style={{color: 'black'}}>
            {I18n.t('confidentiality')}
          </SLink>
          <Link href={routes.termsOfService} style={{color: 'black'}}>
            {I18n.t('termsOfService')}
          </Link>

          <span style={{margin: 20}}>© Biket {new Date().getFullYear()}</span>
        </Footer>
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  height: ${window.innerHeight + 'px'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;

const BaselineContainer = styled.div`
  max-width: ${(305 / 10) * 3};
  margin-top: 20px;
`;

const Baseline = styled.p`
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
`;

const StoresButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SLink = styled(Link)`
  margin: 20px;
`;

const RepairerButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;
