import client from '../util/fetchClient';
import {SEND_NOTIFICATION} from '../firebase/cloudFunctionsRoutes';

export const sendNotification = async (
  userUid: string,
  title: string,
  message: string,
) => {
  if (!userUid) return;
  return client(SEND_NOTIFICATION(userUid, title, message));
};

export const sendBikeEntryNotification = (userUid: string) => {
  const title = 'Vélo reçu';
  const message = 'Le réparateur a indiqué avoir reçu votre vélo';
  return sendNotification(userUid, title, message);
};

export const sendEstimateDoneNotification = (userUid: string) => {
  const title = 'Devis reçu';
  const message = 'Le réparateur vous a envoyé un devis';
  return sendNotification(userUid, title, message);
};

export const sendReparationDoneNotification = (userUid: string) => {
  const title = 'Réparation terminée';
  const message = 'Votre vélo est réparé, vous pouvez le récupérer';
  return sendNotification(userUid, title, message);
};

export const sendControlDoneWithProceduresNotification = (userUid: string) => {
  const title = 'Contrôle terminée';
  const message =
    'Votre vélo a été contrôlé, le réparateur vous a proposé des interventions optionnelles';
  return sendNotification(userUid, title, message);
};

export const sendControlDoneNotification = (userUid: string) => {
  const title = 'Contrôle terminée';
  const message = 'Votre vélo a été contrôlé, vous pouvez le récupérer';
  return sendNotification(userUid, title, message);
};
