import React from 'react';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';

import Login from '../auth/Login';
import MobileAppConfidentiality from './MobileAppConfidentiality';
import routes from './routes';
import Register from '../auth/Register';
import TermsOfService from './TermsOfService';
import Home from './Home';

export default () => {
  return (
    <Router>
      <Switch>
        <Route path={routes.home} exact>
          <Home />
        </Route>

        <Route path={routes.login}>
          <Login />
        </Route>

        <Route path={routes.createAccount} exact>
          <Register />
        </Route>

        <Route path={routes.confidentiality} exact>
          <MobileAppConfidentiality />
        </Route>

        <Route path={routes.termsOfService} exact>
          <TermsOfService />
        </Route>
      </Switch>
    </Router>
  );
};
