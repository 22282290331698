import firebase from 'firebase';

import {users} from '../firebase/firestoreCollections';
import User from './User';

export const getAllUsers = async (): Promise<User[]> => {
  const data = await firebase.firestore().collection(users).get();

  // @ts-ignore
  return data.docs.map(d => d.data());
};

export const createUser = user => {
  return firebase.firestore().collection(users).doc(user.uid).set(user);
};

export const updateUser = (uid, newData) => {
  return firebase.firestore().collection(users).doc(uid).update(newData);
};

export const getUser = uid => {
  return firebase
    .firestore()
    .collection(users)
    .doc(uid)
    .get()
    .then(user => user.data());
};

export const getUserByEmail = async email => {
  const data = await firebase
    .firestore()
    .collection(users)
    .where('email', '==', email)
    .get();

  if (data.docs.length) {
    return data.docs[0].data();
  } else {
    return null;
  }
};
