import React, {useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {useForm} from 'react-hook-form';
import {Button, CardContent, TextField} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import {Email, Face, Lock, Storefront, Home} from '@material-ui/icons';
import firebase from 'firebase';
import {usePlacesWidget} from 'react-google-autocomplete';

import {createUser} from '../users/usersActions';
import I18n from '../translations/i18n';
import isDev from './isDev';
import useStyles from './useStyles';
import routes from '../navigation/routes';
import {createRepairer} from '../repairers/repairerActions';
import {defaultDurations} from '../account/ReparationDurations';
import {defaultReparationPrices} from '../reparations/defaultReparationPrices';

const GOOGLE_PLACES_API_KEY = 'AIzaSyBXQx-dgiv-vrn6p8yPR6kgCrFQdGOsAn8';

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const {addToast} = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();

  const {ref: materialRef} = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    onPlaceSelected: place => {
      setAddress(place.formatted_address);
      setLatitude(place.geometry.location.lat);
      setLongitude(place.geometry.location.lng);
    },
    options: {
      types: [],
    },
  });

  const defaultValues = isDev
    ? {
        firstName: 'simon',
        lastName: 'garnier',
        repairerName: 'test réparateur',
        email: 'biket@test.test',
        password: 'biket@test.test',
      }
    : {firstName: '', lastName: '', repairerName: '', email: '', password: ''};

  const {handleSubmit, register} = useForm({defaultValues});

  const onSubmit = async (data: any): Promise<void> => {
    setIsLoading(true);

    if (
      !data.email ||
      !data.password ||
      !data.firstName ||
      !data.lastName ||
      !data.repairerName ||
      !address
    ) {
      addToast('Veuillez remplir tous les champs', {appearance: 'error'});
      return;
    }

    try {
      const info = await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .catch(error => {
          switch (error.code) {
            case 'auth/email-already-in-use':
              addToast(I18n.t('alreadyUsedEmail'), {
                appearance: 'error',
              });
              break;

            case 'auth/invalid-email':
              addToast(I18n.t('invalidEmail'), {appearance: 'error'});

              break;

            case 'auth/weak-password':
              addToast(I18n.t('tooShortPassword'), {appearance: 'error'});

              break;

            default:
              addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
          }
        });

      if (info) {
        const user = {
          uid: info.user!.uid,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        };

        await Promise.all([
          // auth().currentUser.updateProfile({
          //   displayName: firstName,
          // }),
          createUser(user),
          createRepairer({
            uid: user.uid,
            repairerUid: user.uid,
            email: user.email,
            name: data.repairerName,
            phoneNumber: '',
            address,
            coordinates: new firebase.firestore.GeoPoint(latitude, longitude),
            reparationDurations: defaultDurations,
            reparationPrices: defaultReparationPrices,
          }),
        ]);
        history.push(routes.home);
      }
    } catch (e) {
      console.log('e', e);
      addToast(I18n.t('anErrorOccurred'), {appearance: 'error'});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Card className={classes.loginContainer}>
        <Card className={classes.header} elevation={1} square>
          <p className={classes.headerTitle}>Back-office Biket</p>
        </Card>

        <CardContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.registerCardContent}>
            <div className={classes.cardRow}>
              <Face className={classes.icon} />
              <TextField
                name="firstName"
                inputRef={register({required: I18n.t('firstNameIsRequired')})}
                placeholder="Aron"
                label={I18n.t('firstName')}
                autoCapitalize="words"
                fullWidth
                autoFocus
                required
              />
            </div>

            <div className={classes.cardRow}>
              <Face className={classes.icon} />
              <TextField
                name="lastName"
                inputRef={register({required: I18n.t('lastNameIsRequired')})}
                placeholder="Smith"
                label={I18n.t('lastName')}
                autoCapitalize="words"
                fullWidth
                required
              />
            </div>

            <div className={classes.cardRow}>
              <Storefront className={classes.icon} />
              <TextField
                name="repairerName"
                inputRef={register({required: 'Nom du magasin requis'})}
                placeholder=""
                label={'Nom du magasin'}
                fullWidth
                required
              />
            </div>

            <div className={classes.cardRow}>
              <Home className={classes.icon} />
              <TextField
                inputRef={materialRef}
                fullWidth
                required
                placeholder=""
                label={'Adresse du magasin'}
              />
            </div>

            <div className={classes.cardRow}>
              <Email className={classes.icon} />
              <TextField
                name="email"
                inputRef={register({required: I18n.t('emailIsRequired')})}
                type="email"
                label="Email"
                placeholder="aron@smith.com"
                autoCapitalize="none"
                fullWidth
                required
              />
            </div>

            <div className={classes.cardRow}>
              <Lock className={classes.icon} />
              <TextField
                name="password"
                inputRef={register({required: I18n.t('passwordIsRequired')})}
                label={I18n.t('password')}
                type="password"
                placeholder="**********"
                autoCapitalize="none"
                fullWidth
                required
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                width: '100%',
                marginBottom: -100,
              }}>
              <Button
                onClick={() => history.push(routes.login)}
                className={classes.registerButton}>
                {I18n.t('back')}
              </Button>

              <Button type="submit" className={classes.button}>
                {I18n.t('createAnAccount')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
