import isDev from '../auth/isDev';

const cloudFunctionUrl = isDev
  ? 'http://localhost:5001/biket-62bb2/us-central1/'
  : 'https://us-central1-biket-62bb2.cloudfunctions.net/';

export const SEND_NOTIFICATION = (userUid, title, message) => {
  return (
    cloudFunctionUrl +
    'sendNotification?userUid=' +
    userUid +
    '&title=' +
    title +
    '&message=' +
    message
  );
};
