import React, {useState} from 'react';
import reparationStatutes from '../reparations/reparationStatutes';
import {Button, IconButton, Snackbar, TextField} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

import colors from '../theme/colors';
import reparationTypesEnum from '../entriesAndExits/reparationTypesEnum';
import CloseIcon from '@material-ui/icons/Close';
import {updateReparation} from '../reparations/reparationsActions';
import {useUser} from '../auth/userContext';
import {sendEstimateDoneNotification} from '../notifications/sendNotification';
import SelectReparationsDialog from '../reparations/SelectReparationsDialog';
import problemToReparationLines from '../reparations/problemToReparationLines';

export default ({pendingEstimates}) => {
  const {reparationPrices} = useUser();

  const [filteredPendingEstimates, setFilteredPendingEstimates] = useState<any>(
    pendingEstimates,
  );
  const [estimateToFill, setEstimateToFill] = useState<any>();
  const [estimateToFillLines, setEstimateToFillLines] = useState<any[]>([]);
  const [toastMessage, setToastMessage] = useState<string>();

  const reparationTypeText = entry => {
    if (entry.reparationType === reparationTypesEnum.urgency) return 'Urgence';
    if (entry.reparationType === reparationTypesEnum.appointment)
      return 'Rendez-vous';
    if (entry.reparationType === reparationTypesEnum.immediate)
      return 'Réparation sous 48 heures';
  };

  const sendEstimate = async (estimateLines, total) => {
    try {
      await Promise.all([
        updateReparation(estimateToFill.uid, {
          estimate: estimateLines,
          estimatePrice: total,
          status: reparationStatutes.waitingForEstimateValidation,
        }),
        sendEstimateDoneNotification(estimateToFill.user.uid),
      ]);
      setToastMessage('Devis envoyé');
      setEstimateToFill('');
    } catch (e) {
      console.log('e', e);
      setToastMessage("Une erreur s'est produite, veuillez réessayer");
    }
  };

  const filterByUserName = e => {
    if (e.target && e.target.value !== '') {
      setFilteredPendingEstimates(
        pendingEstimates.filter(b => {
          if (b.user) {
            return (
              b.user.firstName
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) >= 0 ||
              b.user.lastName
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) >= 0
            );
          } else {
            return false;
          }
        }),
      );
    } else {
      setFilteredPendingEstimates(pendingEstimates);
    }
  };

  const selectEstimate = estimate => {
    setEstimateToFill(estimate);

    const lines = problemToReparationLines(estimate.problem, reparationPrices);

    // @ts-ignore
    const uncheckedLines = lines.lines.map(l => ({
      category: lines.category,
      name: l[0],
      price: l[1],
      isSelected: false,
    }));

    setEstimateToFillLines(
      uncheckedLines.sort((a, b) => a.name.localeCompare(b.name)),
    );
  };

  return (
    <>
      <SearchBar>
        <SSearchIcon />
        <TextField
          label="Filtrer par nom de client"
          onChange={filterByUserName}
          name="filter"
        />
      </SearchBar>

      <List dense>
        {filteredPendingEstimates.map((entry, i) => (
          <ListItem
            key={entry.uid}
            style={i % 2 ? {backgroundColor: colors.greyA200} : {}}>
            <ListItemText
              primary={entry.title}
              secondary={moment(
                (entry.start || entry.creationDate).toDate(),
              ).format('LLL')}
            />
            <ReparationType>{reparationTypeText(entry)}</ReparationType>
            <Button
              variant="contained"
              color="primary"
              onClick={() => selectEstimate(entry)}>
              <strong>Faire le devis</strong>
            </Button>
          </ListItem>
        ))}
      </List>

      {estimateToFill && (
        <SelectReparationsDialog
          onCancel={() => setEstimateToFill(null)}
          reparationsLine={estimateToFillLines}
          onSelectReparations={sendEstimate}
          category={
            estimateToFillLines?.length && estimateToFillLines[0].category
          }
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!toastMessage}
        autoHideDuration={3000}
        onClose={() => setToastMessage('')}
        message={toastMessage}
        action={
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => setToastMessage('')}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastMessage('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

const ReparationType = styled.span`
  color: ${colors.palette3};
  font-weight: bold;
  margin-right: 20px;
  font-size: 14px;
`;

const SearchBar = styled.form`
  align-items: end;
  justify-content: center;
  display: flex;
`;

const SSearchIcon = styled(SearchIcon)`
  margin-right: 12px;
  color: ${colors.palette3};
  font-size: 30px;
`;
