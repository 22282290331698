export const controlBeforeSale = 'controlBeforeSale';

const problems = {
  flatTire: 'Pneu crevé',
  brakes: 'Problème de freins',
  gears: 'Problème de vitesse',
  overhaul: 'Révision',
  other: 'Autre...',
  [controlBeforeSale]: 'Contrôle avant vente',
};

export default problems;
