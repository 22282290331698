import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {useToasts} from 'react-toast-notifications';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

import I18n from '../translations/i18n';
import Button from '../components/Button';
import GrayButton from '../components/GrayButton';
import {updateReparation} from '../reparations/reparationsActions';
import reparationStatutes from '../reparations/reparationStatutes';
import {
  sendControlDoneNotification,
  sendControlDoneWithProceduresNotification,
} from '../notifications/sendNotification';
import electricControls from './electricControls';
import mechanicalControls from './mechanicalControls';
import SelectReparationsDialog from '../reparations/SelectReparationsDialog';
import {useUser} from '../auth/userContext';
import problemToReparationLines from '../reparations/problemToReparationLines';
import Stars from './Stars';
import translations from '../reparations/ReparationPricesTranslation';

export default ({control, isModalOpened, openModal}) => {
  const {addToast} = useToasts();
  const classes = useStyles();
  const {reparationPrices} = useUser();

  const [controls, setControls] = useState<any>([]);
  const [showProposeProcedure, setShowProposeProcedure] = useState(false);
  const [currentReparationsLine, setCurrentReparationsLine] = useState<any>([]);
  const [allReparationsLine, setAllReparationsLine] = useState<any>([]);
  const [currentCategory, setCurrentCategory] = useState();

  useEffect(() => {
    setControls(control?.isElectric ? electricControls : mechanicalControls);
  }, [control]);

  const saveEstimate = async () => {
    try {
      await Promise.all([
        updateReparation(control.uid, {
          controlEstimate: allReparationsLine,
          control: controls,
          status: allReparationsLine.length
            ? reparationStatutes.waitingForEstimateValidation
            : reparationStatutes.waitingForRemoval,
        }),
        allReparationsLine.length
          ? sendControlDoneWithProceduresNotification(control.user.uid)
          : sendControlDoneNotification(control.user.uid),
      ]);

      addToast(I18n.t('saved'), {appearance: 'success'});
      return openModal(false);
    } catch (e) {
      addToast(I18n.t('errorOccurred'), {
        appearance: 'error',
      });
    }
  };

  const proposeProcedure = controlIndex => {
    const control = controls[controlIndex];

    const categoryAndLines = problemToReparationLines(
      control.key,
      reparationPrices,
    );

    // @ts-ignore
    const uncheckedLines = categoryAndLines.lines.map(l => ({
      // @ts-ignore
      category: categoryAndLines.category,
      key: categoryAndLines.problem,
      name: l[0],
      price: l[1],
      isSelected: false,
    }));

    setCurrentReparationsLine(
      uncheckedLines.sort((a, b) => a.name.localeCompare(b.name)),
    );
    setCurrentCategory(categoryAndLines.category);

    setShowProposeProcedure(true);
  };

  const changeRating = (newRating, reparationIndex) => {
    setControls(
      controls.map((r, i) => {
        if (i === reparationIndex) return {...r, rating: newRating};
        return r;
      }),
    );
  };

  const addProcedureLines = async (estimateLines, total) => {
    setAllReparationsLine(r => [
      ...estimateLines,
      ...r.filter(l => l.category !== currentCategory),
    ]);

    setShowProposeProcedure(false);
  };

  const ControlsTable = ({controls, checkLine, changeRating}) => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Contrôle</StyledTableCell>
            <StyledTableCell align="center">Note</StyledTableCell>
            <StyledTableCell align="center">Intervention</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {controls.map((control, index) => (
            <StyledTableRow key={control.uid}>
              <StyledTableCell align="center" component="th" scope="row">
                {control.reparationText}
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stars
                  rating={control.rating}
                  changeRating={newRating => changeRating(newRating, index)}
                />
              </StyledTableCell>

              <StyledTableCell align="center">
                <Button onClick={() => checkLine(index)} color="primary">
                  {allReparationsLine.map(l => l.key).indexOf(control.key) ===
                  -1
                    ? 'Proposer une intervention'
                    : 'Modidier'}
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={isModalOpened}
        onClose={() => openModal(false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Contrôle avant vente</DialogTitle>

        <DialogContent>
          <ControlsTable
            controls={controls}
            checkLine={proposeProcedure}
            changeRating={changeRating}
          />

          <ul>
            {allReparationsLine.map(r => {
              return (
                <ReparationLine>
                  {translations[r.name] || r.name} {r.price}€
                </ReparationLine>
              );
            })}
          </ul>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-evenly',
          }}>
          <GrayButton onClick={() => openModal(false)}>Annuler</GrayButton>
          <Button onClick={saveEstimate} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>

      {showProposeProcedure && (
        <SelectReparationsDialog
          onCancel={() => setShowProposeProcedure(false)}
          reparationsLine={currentReparationsLine}
          category={currentCategory}
          selectedLines={allReparationsLine}
          onSelectReparations={addProcedureLines}
        />
      )}
    </>
  );
};

const StyledTableCell = withStyles(({palette}: Theme) =>
  createStyles({
    head: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {},
});

const ReparationLine = styled.li`
  font-size: 24px;
`;
