import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
} from '@material-ui/core';
import React, {useState} from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';
import ImageSlides from 'react-imageslides';

const EventModal = ({
  event,
  isEventModalOpened,
  openModal,
  deleteEvent,
  upsertEvent,
  onEventTitleChange,
  onEventDescriptionChange,
}) => {
  const [showSlides, setShowSlides] = useState(false);

  if (!event) return <></>;

  const onPressEnter = e => {
    if (e.keyCode === 13) {
      upsertEvent();
    }
  };

  const openSlides = () => {
    setShowSlides(true);
  };

  const problemPhotos = event.resource?.photos ? event.resource.photos : [];

  const photos = (event.resource?.bike
    ? [event.resource.bike.photoUrl].concat(problemPhotos)
    : problemPhotos
  ).filter(i => i);

  if (showSlides) {
    return (
      <ImageSlides
        index={0}
        tapClose={true}
        onClose={() => setShowSlides(false)}
        images={photos}
        isOpen={showSlides}
        showPageButton
        addon={() => {
          return (
            <a
              href="/#"
              style={{
                position: 'absolute',
                color: 'white',
                top: 10,
                right: 30,
                fontSize: 40,
                zIndex: 999,
                fontWeight: 'bold',
                height: 50,
                width: 150,
              }}
              onClick={() => setShowSlides(false)}>
              Fermer
            </a>
          );
        }}
      />
    );
  }

  return (
    <Dialog
      open={isEventModalOpened}
      onClose={() => openModal(false)}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Réparation</DialogTitle>

      <DialogContent>
        <TextField
          defaultValue={event ? event!.title : ''}
          onKeyDown={onPressEnter}
          onChange={onEventTitleChange}
          autoFocus
          margin="dense"
          id="name"
          label="Titre"
          type="text"
          fullWidth
        />
        <TextField
          defaultValue={
            event && event.resource && event.resource.description
              ? event!.resource.description
              : ''
          }
          onKeyDown={onPressEnter}
          onChange={onEventDescriptionChange}
          margin="dense"
          label="Description falcutative"
          type="text"
          fullWidth
        />

        <p>
          {event && event.resource && event.resource.user && (
            <p>
              <b>Nom du client :</b> {event.resource.user.firstName}{' '}
              {event.resource.user.lastName}
              <br />
              <b>Email :</b> {event.resource.user.email}
            </p>
          )}
        </p>
        {photos.length ? (
          <Link href="#" onClick={openSlides} style={{fontWeight: 'bold'}}>
            {photos.length === 1 && 'Voir la photo'}
            {photos.length > 1 && 'Voir les photos'}
          </Link>
        ) : (
          <></>
        )}
      </DialogContent>

      <DialogActions style={{justifyContent: 'space-between'}}>
        {event && event!.resource && event!.resource.eventUid ? (
          <RedButton onClick={deleteEvent}>Supprimer la tâche</RedButton>
        ) : (
          <div />
        )}
        <div>
          <Button onClick={() => openModal(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={upsertEvent} color="primary">
            Valider
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EventModal;

const RedButton = styled(Button)`
  background-color: ${colors.palette2};
  color: white;
`;
