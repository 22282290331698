import React from 'react';
import Card from '@material-ui/core/Card';
import {
  CardContent,
  Snackbar,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import {Face, Lock} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import {useForm} from 'react-hook-form';
import firebase from 'firebase';
import {useHistory} from 'react-router-dom';

import I18n from '../translations/i18n';
import useStyles from './useStyles';
import routes from '../navigation/routes';

export default () => {
  const classes = useStyles();
  const history = useHistory();

  const [isErrorModalOpened, setIsErrorModalOpened] = React.useState(false);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsErrorModalOpened(false);
  };

  const defaultValues =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? {
          email: 'biket@test.test',
          password: 'testtest',
        }
      : {email: '', password: ''};

  const {handleSubmit, register} = useForm({defaultValues});

  const onSubmit = (data: any) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .catch(() => {
        setIsErrorModalOpened(true);
      });
  };

  return (
    <div className={classes.mainContainer}>
      <Card className={classes.loginContainer}>
        <Card className={classes.header} elevation={1} square>
          <p className={classes.headerTitle}>Back-office Biket</p>
        </Card>

        <CardContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.cardContent}>
            <div className={classes.cardRow}>
              <Face className={classes.icon} />
              <TextField
                inputRef={register({required: I18n.t('emailIsRequired')})}
                label={I18n.t('email')}
                type="email"
                name="email"
                fullWidth
                autoFocus
                required
              />
            </div>

            <div className={classes.cardRow}>
              <Lock className={classes.icon} />
              <TextField
                inputRef={register({required: I18n.t('passwordIsRequired')})}
                label={I18n.t('password')}
                type="password"
                name="password"
                fullWidth
                required
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                width: '100%',
              }}>
              <Button
                onClick={() => history.push(routes.createAccount)}
                variant="contained"
                className={classes.registerButton}>
                {I18n.t('createAnAccount')}
              </Button>

              <Button
                variant="contained"
                className={classes.button}
                type="submit">
                {I18n.t('login')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isErrorModalOpened}
        autoHideDuration={4500}
        onClose={handleClose}
        message="Identifiants incorrects"
        action={
          <>
            <Button color="secondary" size="small" onClick={handleClose}>
              Fermer
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};
