export default {
  errorOccurred: 'An error occurred',
  myAccount: 'My account',
  myStatistics: 'My statistics',
  profilePicture: 'Profile picture',
  notDefined: 'Not defined',
  unknown: 'Unknown',
  saved: 'Saved',
  youMustLogInAgain: 'You must log in again in order to modify you email',
  password: 'Password',
  cancel: 'CANCEL',
  submit: 'SUBMIT',
  terminate: 'TERMINATE',
  confirm: 'Confirm',
  youMustLogInAgainToDeleteYourAccount:
    'You must log in again to delete your account',
  emailModification: 'Email modification',
  accountDeletion: 'Account deletion',
  firstName: 'First name',
  lastName: 'Last name',
  gender: 'Gender',
  firm: 'Firm',
  email: 'Email',
  country: 'Country',
  deleteMyAccount: 'Delete my account',
  logout: 'LOGOUT',
  female: 'Female',
  male: 'Male',
  back: 'Back',
  emailSent: 'Email sent',
  sendEmail: 'SEND THE EMAIL',
  login: 'LOGIN',
  createMyAccount: 'CREATE MY ACCOUNT',
  create: 'Create',
  loading: 'Loading...',
  close: 'CLOSE',
  continue: 'CONTINUE',
  minutes: 'minutes',
  seconds: 'seconds',
  hour: 'hour',
  hourAndHalf: 'hour and half',
  hours: 'hours',
  anErrorOccurred: 'An error occurred',
  sorryAnErrorOccurred: 'Sorry, an error occurred',
  date: 'Date',
  time: 'time',
  times: 'times',
  hello: 'Hello ',

  waitingMatchingRepairers: 'En attente de réparateurs',
  bikeNotReceivedYet: 'Vélo à emmener au réparateur',
  waitingForEstimate: 'En attente de devis',
  waitingForEstimateValidation: 'Devis à valider',
  estimateRefused: 'Devis refusé',
  waitingForReparation: 'Travail en cours',
  waitingForRemoval: 'À récupérer',
  tookBackByOwner: 'Vélo réceptionné',
  bikeReceived: 'Vélo déposé',
  repairerSelected: 'Réparateur sélectionné',
  runningControl: 'Vélo en cours de contrôle',
  certifiedControl: 'Contrôle certifié',
  optionalOperations: 'Interventions optionnelles',
  repairedBike: 'Vélo réparé, prêt à être récupéré',
  codeToBeGiven: 'Code à donner : ',
};
